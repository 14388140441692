
.Donate {
  text-align: center;

  h1 {
    margin-bottom: 30px;
    color: $primary-color;
  }

  .heading {
    margin: 0 auto;
    margin-bottom: 40px;
    max-width: 700px;

    .has-text-align-left {
      text-align: left;
    }

    p {
      font-weight: 400;
    }
  }
}

.Donate .Form, .Donate-Embed .Form {
  margin: 0 auto;
  margin-bottom: 100px;
  max-width: 700px;
  text-align: center;

  h3 {
    font-family: $primary-font;
    text-transform: none;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: left;
  }

  .email, .name, .contact, .amount {
    label {
      font-size: 1rem;
      color: #545454;
    }

    input {
      font-size: 1rem;
      font-weight: 400;
    }

    .MuiSelect-select {
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
    }

    span.MuiFormControlLabel-label {
      font-size: 1rem;
    }

    .MuiSvgIcon-root {
      font-size: 1.2rem;
    }
  }

  .name, .two-column {
    @include flex-row();
    justify-content: space-between;

    @include small-device {
      display: block;
    }

    > div {
      width: 49%;

      @include small-device {
        width: 100%;
      }
    }
  }

  .amount {
    @include flex-row();
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @include small-device {
      display: block;
      text-align: left;
    }

    .FormField-control {
      width: 200px;

      @include small-device {
        margin-bottom: 20px;
      }
    }

    .amount-cents {
      background-color: $light-bg-color;
      padding: 14px;
      margin-right: -10px;
    }

    .frequency-field {
      flex: 1;
      display: block;
      text-align: left;
      padding-left: 30px;
      padding-top: 10px;
      box-sizing: border-box;

      @include small-device {
        width: 100%;
        padding-left: 0px;
        margin-bottom: 20px;
      }

      .MuiFormControlLabel-root {
        margin-left: 5px;
      }

      .MuiIconButton-label {
        margin-right: 5px;
      }

      .MuiIconButton-root {
        padding: 0px;
      }

      .MuiIconButton-root.Mui-checked {
        color: $info-color;
      }
    }
  }

  .card {
    margin-bottom: 30px;

    .StripeElement {
      margin-bottom: 5px;
    }
  }

  button {
    margin-top: 30px;
  }
}

.DonateAmount {
  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;

    .heading-text {
      flex: 1;
    }

    .secured {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.8em;
      font-weight: 400;

      .Icon {
        margin-right: 5px;
        font-size: 1.3em;
      }
    }
  }
}

.Donate-credit {
  margin-top: 60px;
  margin-bottom: 80px;
}

.Donate-other {
  @include flex-row();
  justify-content: space-between;
  max-width: 700px; //500px;
  margin: 0 auto;
  margin-bottom: 60px;

  @include small-device {
    display: block;
  }

  > div {
    width: 49%;

    h4 {
      color: $primary-color;
      margin-bottom: 15px;
    }

    strong {
      display: block;
      font-weight: 500;
    }

    @include small-device {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}

.Donate-other.single {
  display: block;

  h4 {
    margin-bottom: 15px;
  }
}

.Donate-information {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.Donations {
  margin-bottom: 60px;

  .Donations-loading {
    text-align: center;
  }

  .LoadingMessage {
    margin: 0 auto;
  }

  .disclaimer-message {
    margin-bottom: 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 35px;
  }

  h2 {
    text-transform: none;
    margin-bottom: 20px;
  }

  .content {
    max-width: 700px;
    margin: 0 auto;
    text-align: left;
  }
}

.RecurringDonations {
  margin-top: 45px;

  .recurring-donation {
    margin-bottom: 40px;

    .amount {
      font-size: 1em;
      margin-bottom: 5px;
      font-weight: 500;
    }

    .payment-method {
      font-size: 1em;
      margin-bottom: 5px;

      span {
        display: inline-block;
        margin-right: 7px;
        font-weight: 500;
      }
    }

    .dates {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;

      @include small-device {
        display: block;

        > div {
          margin-bottom: 5px;
        }
      }

      .created {
        margin-right: 30px;
      }

      span {
        margin-right: 10px;
        font-weight: 500;
      }
    }

    .actions {
      button {
        font-size: 0.9em;
        margin-right: 15px;

        @include small-device {
          display: block;
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.ScheduledDonations {
  margin-top: 55px;

  .scheduled-donation {
    .amount {
      display: inline-block;
      width: 150px;
      font-size: 1em;
      margin-bottom: 15px;
      font-weight: 500;
    }

    .date {
      display: inline-block;
      
      span {
        font-weight: 500;
      }

      @include small-device {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.DonationHistory {
  margin-top: 55px;

  .donation {
    font-size: 1em;
    margin-bottom: 20px;

    .date {
      width: 150px;
      display: inline-block;
      font-weight: 500;

      @include small-device {
        display: block;
        margin-bottom: 5px;
      }
    }

    .notation {
      padding-left: 150px;

      @include small-device {
        padding-left: 0px;
      }

      span {
        font-weight: 400;
      }

      span.not-empty {
        display: block;
      }
    }
  }

  .error {
    color: $error-color;
    margin-top: 45px;
    font-weight: 500;
    font-size: 1.1em;
  }
}

.DonationCancel-dialog {
  h2 {
    font-size: 1.1em;
  }

  span.donation {
    font-weight: 500;
    color: $constrast-text-color;
  }
}

.UpdateDonationAmount {
  h2 {
    margin-bottom: 15px;
  }

  .current {
    margin-bottom: 20px;
    
    span {
      font-weight: 500;
      margin-right: 15px;
    }
  }

  label {
    font-size: 1rem;
    color: #545454;
  }

  input {
    font-size: 1rem;
    font-weight: 400;
  }

  .FormField-control {
    width: 200px;
  }

  .amount-cents {
    background-color: $light-bg-color;
    padding: 14px;
    margin-right: -10px;
  }
}

.SelectDonationCard {
  .MuiSelect-select {
    width: 350px;
  }
}

.StripeElement {
  box-sizing: border-box;
  margin-bottom: 20px;

  height: 52px;

  padding: 18px 12px;

  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.DonateSuccess {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;

  p.thanks {
    font-size: 2em;
    font-weight: 400;
  }

  p.message {
    margin-top: 30px;
    font-size: 1.3em;
    font-weight: 300;
    text-align: left;
  }

  p.signature {
    margin-top: 40px;
    font-size: 1.3em;
    font-weight: 500;
  }

  div.DonateSuccess-snippet {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 60px;
    text-align: center;
  }
}